import React from 'react';
import { Link } from 'react-router-dom';
import './NotFoundPage.css'; // Подключаем стили

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <img src={require('../assets/error-404.png')} alt="404 Error" className="error-image" />
      <h1>Page Not Found</h1>
      <Link to="/" className="back-home-button">Go Back Home</Link>
    </div>
  );
};

export default NotFoundPage;
