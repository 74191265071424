import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar'; // Импортируем Navbar
import Home from './pages/Home';
import { CartProvider } from './CartContext';
import Cart from './pages/Cart';
import Game from './pages/Game';
import Checkout from './pages/Checkout';
import ProductDetails from './pages/ProductDetails';
import Account from './pages/UserProfile';
import AddProd from './pages/AddProductForm';
import ProductList from './components/ProductList';
import GiftsPage from './pages/GiftsPage';
import NotFoundPage from './pages/NotFoundPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import CookieConsent from './components/CookieConsent';

const App = () => {
  return (
    <div style={{ 
      minHeight: '100vh',
      background: 'linear-gradient(135deg, #0c0e27, #3a3136)',
    }}>
      <CookieConsent />
      <CartProvider>
        <Router>
          {/* Добавляем Navbar здесь, чтобы он отображался на всех страницах */}
          <Navbar /> 
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/game" element={<Game />} />
            <Route path="/admin/add" element={<AddProd />} />
            <Route path="/admin/products" element={<ProductList />} />
            <Route path="/profile" element={<Account />} />
            <Route path="/gifts" element={<GiftsPage />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/catalog" element={<div>Catalog Page</div>} />
            <Route path="/about" element={<div>About Us Page</div>} />
            <Route path="/contact" element={<div>Contact Page</div>} />
            <Route path="/product/:id" element={<ProductDetails />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </CartProvider>
    </div>
  );
};

export default App;
