import React, { useState } from 'react';
import './footer.css';
import logo from '../assets/logo.png';
import ContactUsModal from './ContactUsModal'; // Import the modal component

const Footer = () => {
  const [isContactOpen, setIsContactOpen] = useState(false);

  return (
    <footer className="footer">
      <div className="footer-left">
        <img src={logo} alt="Logo" className="footer-logo" />
        <p>
          OldSikns<br />
          No. 127896<br />
          Registration address: 579 City Road, London, United Kingdom, EC4A 5NX<br />
          Copyright © 2024. All Rights Reserved.
        </p>
      </div>
      <div className="footer-center">
        <nav>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/game">Shop</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
            <li><button className="contact-link" onClick={() => setIsContactOpen(true)}>Contact Us</button></li>
            <li></li>
            <li><img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/1200px-Mastercard_2019_logo.svg.png"
            alt="MasterCard"
            className="footer-payment-icon"
            /></li>
            <li><img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/640px-Visa_2021.svg.png"
            alt="Visa"
            className="footer-payment-icon"
            /></li>
          </ul>
        </nav>
      </div>
      {/* Contact Us Modal */}
      <ContactUsModal isOpen={isContactOpen} onClose={() => setIsContactOpen(false)} />
    </footer>
  );
};

export default Footer;
