import React, { useState, useEffect } from 'react';
import './CookieConsent.css';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="cookie-consent-modal">
        <div className="cookie-consent-content">
          <h2>We use cookies</h2>
          <p>
            This website uses cookies to enhance user experience. By clicking “Accept All,”
            you consent to our use of cookies. For more information, please see our{' '}
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>.
          </p>
          <div className="cookie-consent-buttons">
            <button onClick={handleAccept} className="cookie-accept-button">
              Accept All
            </button>
            <button onClick={handleDecline} className="cookie-decline-button">
              Decline All
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default CookieConsent;
