import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserProfile.css';
import Footer from '../components/footer'; // Если нужно подключить футер
import avatar from '../assets/avatar-big.png'; // Логотип аватара пользователя
import editIcon from '../assets/edit.png'; // Иконка редактирования
import MobNavbar from '../components/MobNav';
import loadingGif from '../assets/loading.gif';
import { Link , useNavigate } from 'react-router-dom';

const UserProfile = () => {
  
  const [userData, setUserData] = useState(null); // Хранит данные о пользователе
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false); // Режим редактирования
  const [updatedData, setUpdatedData] = useState({}); // Хранит обновленные данные
  const [isAdmin, setIsAdmin] = useState(false); // New state for admin status
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showAdminDropdown, setShowAdminDropdown] = useState(false);
  const [showTradeLinkError, setShowTradeLinkError] = useState(false); 
  const [timers, setTimers] = useState({});
  const [loadingItem, setLoadingItem] = useState(null);
  
  const navigate = useNavigate();
  const toggleAdminDropdown = () => {
    setShowAdminDropdown((prev) => !prev);
  };
  const handleLogOut = () => {
    localStorage.removeItem('token');
    navigate('/'); // Redirect to homepage
  };
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        setError('You are not logged in');
        setLoading(false);
        return;
      }
  
      try {
        // Fetch user profile data
        const response = await axios.get('https://www.kerambit.me/api/user/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = response.data;
        setUserData({ ...userData, purchaseHistory: userData.purchaseHistory || [] });
        setUpdatedData(response.data);
  
        // Attempt to check if user is admin
        try {
          const accessResponse = await axios.get('https://www.kerambit.me/api/admin/check-access', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (accessResponse.status === 200) {
            setIsAdmin(true);
          }
        } catch (adminError) {
          if (adminError.response && adminError.response.status === 404) {
            console.log('Admin check endpoint not found. Non-admin access confirmed.');
          } else {
            console.error('Error checking admin access:', adminError);
          }
          setIsAdmin(false); // Explicitly set isAdmin to false for non-admins
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to fetch user data');
      } finally {
        setLoading(false);
      }
    };
    const storedTimers = JSON.parse(localStorage.getItem('timers')) || {};
    setTimers(storedTimers);
    fetchUserData();
  }, []);
  
  useEffect(() => {
    localStorage.setItem('timers', JSON.stringify(timers));
  }, [timers]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData({ ...updatedData, [name]: value });
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleSave = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.put('https://www.kerambit.me/api/user/profile', updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(updatedData); // Обновляем данные пользователя в состоянии
      setIsEditing(false); // Отключаем режим редактирования
      alert('Profile updated successfully');
    } catch (err) {
      console.error('Error updating profile:', err);
      alert('Failed to update profile');
    }
  };
  const handleReceiveItem = async (itemId, itemName, maxPrice, partner, token) => {
    if (!userData.steamtradelink) {
      setShowTradeLinkError(true);
      return;
    }
    setLoadingItem(itemId); 
    try {
      const response = await axios.post(
        `https://www.kerambit.me/api/user/receive-item/${itemId}`,
        { itemId, itemName, maxPrice, partner, token },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );

      if (response.status === 200) {
        startTimer(itemId); // Запускаем таймер для отслеживания статуса
      }
    } catch (error) {
      console.error('Ошибка при получении товара:', error);
    } finally {
      setLoadingItem(null); // Убираем состояние загрузки после выполнения запроса
    }
  };

  const startTimer = (itemId) => {
    setTimers((prev) => ({
      ...prev,
      [itemId]: { timeLeft: 600, checking: true } // 10 минут в секундах
    }));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimers((prev) => {
        const newTimers = { ...prev };
        Object.keys(newTimers).forEach((itemId) => {
          if (newTimers[itemId].checking && newTimers[itemId].timeLeft > 0) {
            newTimers[itemId].timeLeft -= 1;
          } else if (newTimers[itemId].timeLeft === 0) {
            checkPurchaseStatus(itemId);
          }
        });
        return newTimers;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const checkPurchaseStatus = async (itemId) => {
    try {
      const response = await axios.get(`https://www.kerambit.me/api/user/check-status/${itemId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const { stage } = response.data;

      if (stage === 2) {
        setUserData((prevData) => ({
          ...prevData,
          purchaseHistory: prevData.purchaseHistory.map((item) =>
            item.itemID === itemId ? { ...item, status: 'Received' } : item
          ),
        }));
        setTimers((prev) => ({ ...prev, [itemId]: { ...prev[itemId], checking: false } }));
      } else if (stage === 5) {
        setTimers((prev) => ({ ...prev, [itemId]: { ...prev[itemId], checking: false } }));
        alert('Purchase timed out. Please try receiving the item again.');
      }
    } catch (error) {
      console.error('Ошибка при проверке статуса покупки:', error);
    }
  };

  


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={`user-profile-page `}>
      <MobNavbar />
      {showTradeLinkError && (
        <div className="error-popup">
          <p>Trade link is not provided. Please update your profile with a valid Steam trade link.</p>
          <button onClick={() => setShowTradeLinkError(false)}>Close</button>
        </div>
      )}
      <div className="user-profile">
        <div className="profile-container">
          {/* Левая часть с аватаром */}
          <div className="avatar-section">
            <img src={avatar} alt="User Avatar" className="avatar-image" />
          </div>

          {/* Правая часть с личной информацией */}
          <div className="details-section">
            <h1 className="profile-title">Personal Details</h1>

            {userData && (
              <div className="details-info">
                <div className="info-field">
                  <span className="info-label">Name</span>
                  {isEditing ? (
                    <input
                      type="text"
                      name="username"
                      value={updatedData.username}
                      onChange={handleInputChange}
                      className="user-input"
                      placeholder="Enter your username"
                    />
                  ) : (
                    <span className="info-value">{userData.username}</span>
                  )}
                </div>
                <div className="info-field">
                  <span className="info-label">Email</span>
                  {isEditing ? (
                    <input
                      type="email"
                      name="email"
                      value={updatedData.email}
                      onChange={handleInputChange}
                      className="user-input"
                      placeholder="Enter your email"
                    />
                  ) : (
                    <span className="info-value">{userData.email}</span>
                  )}
                </div>
                <div className="info-field">
                  <span className="info-label">Link on Steam account</span>
                  {isEditing ? (
                    <input
                      type="text"
                      name="steamacclink"
                      value={updatedData.steamacclink || ''}
                      onChange={handleInputChange}
                      className="user-input"
                      placeholder="Enter your Steam link"
                    />
                  ) : (
                    <a
                      href={userData.steamacclink || '#'}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="info-value"
                    >
                      {userData.steamacclink || 'Not linked'}
                    </a>
                  )}
                </div>
                <div className="info-field">
                  <span className="info-label">Steam Trade Link</span>
                  {isEditing ? (
                    <input
                      type="email"
                      name="steamtradelink"
                      value={updatedData.steamtradelink}
                      onChange={handleInputChange}
                      className="user-input"
                      placeholder="Enter your Steam trade link"
                    />
                  ) : (
                    <span className="info-value">{userData.steamtradelink}</span>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* Кнопка редактирования или сохранения */}
          <div className="edit-button">
            {isEditing ? (
              <button onClick={handleSave}>
                <img src={editIcon} alt="Save" />
                Save
              </button>
            ) : (
              <button onClick={handleEditToggle}>
                <img src={editIcon} alt="Edit" />
                Edit
              </button>
            )}
          </div>
        </div>

        <div className="transaction-section">
          <h2 className="transaction-title">Transactions</h2>
          <div className="transaction-items">
            {userData.purchaseHistory && userData.purchaseHistory.length > 0 ? (
              userData.purchaseHistory.map((item, index) => (
                <div className="product-cart" key={`${item.itemID}-${index}`} data-rarity={item.product?.Rarity || 'Unknown'}>
                  <div className="product-cart_inside">
                    <span className="product-game-quality-tag">{item.product?.wear || 'Unknown'}</span>
                    <div className="product-image-container">
                      <img
                        src={
                          item.product?.imageUrl.startsWith('/images/')
                            ? item.product.imageUrl  // Если путь начинается с /images/, используем его как есть
                            : require(`../assets/${item.product?.imageUrl}`) // Для старых данных используем require
                        }
                        alt={item.product?.name || 'Unknown'}
                        className="product-game-image"
                      />
                    </div>
                    <div className="product-game-info">
                      <div className="product-game-name-category">
                        <span className="product-game-category">
                          {item.product?.category || 'Category'} • {item.product?.name || 'Unknown'}
                        </span>
                        <h4 className="product-name">{item.product?.description || 'Description not available'}</h4>
                      </div>
                        <p className="product-game-price">{item.price} €</p>
                        <p>Date: {new Date(item.purchaseDate).toLocaleDateString()}</p>
                        <p>Status: {item.status}</p>

                    </div>
                    <div className="product-game-footer">
                    {item.status === 'Not Received' && timers[item.itemID] ? (
                        timers[item.itemID].timeLeft > 0 ? (
                          <span className="timer">
                            Time left: {Math.floor(timers[item.itemID].timeLeft / 60)}:
                            {timers[item.itemID].timeLeft % 60 < 10 ? '0' : ''}
                            {timers[item.itemID].timeLeft % 60}
                          </span>
                        ) : (
                          loadingItem === item.itemID ? (
                            <img src={loadingGif} alt="Loading..." className="loading-gif" />
                          ) : (
                            <button
                              className="receive-button"
                              onClick={() => handleReceiveItem(item.itemID, item.product?.name, item.price, userData.steamtradelink.partner, userData.steamtradelink.token)}
                            >
                              Получить товар
                            </button>
                          )
                        )
                      ) : item.status === 'Received' ? (
                        <div className="received-overlay">
                          <svg fill="#39a24b" height="50px" width="50px" viewBox="0 0 568.599 568.599">
                            <path d="M565.692,147.211L507.96,89.479c-4.08-4.08-10.404-4.08-14.484,0L241.128,342.031L75.276,176.179 c-4.08-4.08-10.404-4.08-14.484,0L3.06,233.911c-4.08,4.08-4.08,10.404,0,14.484l230.724,230.724 c1.836,1.836,4.488,3.06,7.14,3.06s5.304-1.02,7.14-3.06l317.628-317.424C569.568,157.615,569.568,151.291,565.692,147.211z M241.128,457.495L24.684,241.051l43.248-43.248l165.852,165.852c4.08,4.08,10.404,4.08,14.484,0L500.82,111.103l43.248,43.248 L241.128,457.495z"></path>
                          </svg>
                        </div>
                      ) : (
                        <button
                          className="receive-button"
                          onClick={() => handleReceiveItem(item.itemID, item.product?.name, item.price, userData.steamtradelink.partner, userData.steamtradelink.token)}
                        >
                          Получить товар
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="no-transaction">You haven't made any purchases yet</p>
            )}
          </div>
        </div>


              {/* Render Admin Panel button only if isAdmin is true */}
              {isAdmin && (
                <div className="button-row">
                  <button className="admin-link" onClick={toggleAdminDropdown}>
                    Admin Panel
                  </button>
                  {showAdminDropdown && (
                    <div className="admin-dropdown">
                      <Link to="/admin/add" className="admin-dropdown-link">Add Items</Link>
                      <Link to="/admin/products" className="admin-dropdown-link">Edit Items</Link>
                    </div>
                  )}
                  {isMobile && (
                    <button onClick={handleLogOut} className="acc-logout-button">
                      Log Out
                    </button>
                  )}
                </div>
              )}
      </div>   


      {!isMobile && <Footer />} {/* Only show Footer on non-mobile screens */}
    </div>
  );
};

export default UserProfile;
