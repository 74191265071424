import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ProductList.css'; // Подключаем стили

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [editedProduct, setEditedProduct] = useState(null); // Для редактирования продуктов
  const [newImage, setNewImage] = useState(null); // Для изменения изображения

  // Получение всех продуктов
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://www.kerambit.me/api/admin/all-products');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminAccess = async () => {
      try {
        const token = localStorage.getItem('token');
        await axios.get('https://www.kerambit.me/api/admin/check-access', {
          headers: { Authorization: `Bearer ${token}` }
        });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          navigate('*'); // Редирект на NotFoundPage при 404 ошибке
        }
      }
    };

    checkAdminAccess();
  }, [navigate]);
  const handleUpdateProduct = async (productId) => {
    try {
      const token = localStorage.getItem('token'); // Получаем токен из localStorage
      const response = await axios.put(
        `https://www.kerambit.me/api/admin/update-product/${productId}`,  // Убедитесь, что здесь правильный productId
        editedProduct,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setProducts((prevProducts) =>
        prevProducts.map((product) => (product._id === productId ? response.data.product : product))
      );
      setEditedProduct(null); // Сброс формы редактирования
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };
  
  
  // Обработчик удаления товара
  const handleDeleteProduct = async (productId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`https://www.kerambit.me/api/admin/delete-product/${productId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProducts(products.filter((product) => product._id !== productId));
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

 // Обработчик изменения изображения
const handleImageChange = async (productId) => {
  try {
    if (!newImage) return;

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('image', newImage);

    const response = await axios.put(
      `https://www.kerambit.me/api/admin/update-image/${productId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      }
    );

    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product._id === productId ? response.data.product : product
      )
    );
    setNewImage(null); // Сброс загруженного изображения
    setEditedProduct(null); // Скрыть форму редактирования при успешном обновлении
  } catch (error) {
    console.error('Error changing image:', error);
  }
};

  // Обработчик изменений для редактируемого продукта
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="product-list">
      <h1>Product List</h1>
      {products.map((product) => (
        <div key={product._id} className="product-item">
          {editedProduct && editedProduct._id === product._id ? (
            <div className="edit-product-form">
              <input
                type="text"
                name="name"
                value={editedProduct.name}
                onChange={handleEditChange}
                placeholder="Name"
              />
              <input
                type="number"
                name="price"
                value={editedProduct.price}
                onChange={handleEditChange}
                placeholder="Price"
              />
              <textarea
                name="description"
                value={editedProduct.description}
                onChange={handleEditChange}
                placeholder="Description"
              ></textarea>
              <input
                type="number"
                name="stock"
                value={editedProduct.stock}
                onChange={handleEditChange}
                placeholder="Stock"
              />
              <select
                name="category"
                value={editedProduct.category}
                onChange={handleEditChange}
              >
                <option value="Knives">Knives</option>
                <option value="Rifles">Rifles</option>
                <option value="Gloves">Gloves</option>
                <option value="Pistols">Pistols</option>
                <option value="SMGs">SMGs</option>
                <option value="Shotguns">Shotguns</option>
                <option value="Machine guns">Machine guns</option>
                <option value="Key">Key</option>
              </select>
              <select
                name="Rarity"
                value={editedProduct.Rarity}
                onChange={handleEditChange}
              >
                <option value="Covert">Covert</option>
                <option value="Classified">Classified</option>
                <option value="Restricted">Restricted</option>
                <option value="Mil-Spec Grade">Mil-Spec Grade</option>
                <option value="Industrial Grade">Industrial Grade</option>
                <option value="Knife">Knife</option>
              </select>
              <select
                name="wear"
                value={editedProduct.wear}
                onChange={handleEditChange}
              >
                <option value="Factory new">Factory new</option>
                <option value="Minimal wear">Minimal wear</option>
                <option value="Field-tested">Field-tested</option>
                <option value="Well-worn">Well-worn</option>
              </select>
              <input
                type="file"
                onChange={(e) => setNewImage(e.target.files[0])}
              />
              <button className="button-admin-1" onClick={() => handleUpdateProduct(product._id)}>Save</button>
              <button className="button-admin-1" onClick={() => setEditedProduct(null)}>Cancel</button>
              <button className="button-admin-1" onClick={() => handleImageChange(product._id)}>Change Image</button>
            </div>
          ) : (
            <div className="product-content">
              <div className="product-details">
                <p>Name: {product.name}</p>
                <p>Price: {product.price}</p>
                <p>Description: {product.description}</p>
                <p>Stock: {product.stock}</p>
                <p>Category: {product.category}</p>
                <p>Rarity: {product.Rarity}</p>
                <p>Wear: {product.wear}</p>
              </div>
              <img
                  src={
                    product.imageUrl.startsWith('/images/') 
                      ? product.imageUrl  // Если путь начинается с /images/, используем его как есть
                      : require(`../assets/${product.imageUrl}`) // Для старых данных используем require
                  }
                  alt={product.name}
                  className="product-add-image"
              />
              <button className="button-admin"onClick={() => setEditedProduct(product)}>Edit</button>
              <button className="button-admin" onClick={() => handleDeleteProduct(product._id)}>Delete</button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProductList;
