import React, { useState, useEffect } from 'react';
import './MobNav.css';
import { Link, useLocation } from 'react-router-dom';
import homeWhite from '../assets/home-white.svg';
import homePink from '../assets/home-pink.svg';
import profileWhite from '../assets/profile-white.svg'; // Иконка для неавторизованного пользователя
import knifeWhite from '../assets/knife-white.svg';
import knifePink from '../assets/knife-pink.svg';
import shopWhite from '../assets/shop-white.svg';
import shopPink from '../assets/shop-pink.svg';
import avatarIcon from '../assets/avatar.png'; // Стандартная иконка аватара
import LoginModal from './LoginModal'; // Модальное окно для входа
import axios from 'axios';

const MobNav = () => {
    const location = useLocation(); // Хук для получения текущего пути
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [cartItemCount, setCartItemCount] = useState(0);

    const handleLogin = () => {
        setLoginModalOpen(true); // Открываем модальное окно для входа
    };

    const handleCloseModal = () => {
        setLoginModalOpen(false); // Закрываем модальное окно
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setUser(null);
    };

    const handleUserLogin = (userData, token) => {
        setIsLoggedIn(true);
        setUser(userData);
        localStorage.setItem('token', token);
        setLoginModalOpen(false); // Закрываем модальное окно после входа
    };

    const fetchUserProfile = async (token) => {
        try {
            if (!token) {
                console.log('Токен не найден, пользователь не авторизован');
                return;
            }

            const response = await axios.get('https://www.kerambit.me/api/user/profile', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUser(response.data); // Устанавливаем данные пользователя
            setIsLoggedIn(true); // Пользователь авторизован
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log('Пользователь не авторизован');
            } else {
                console.error('Произошла ошибка при загрузке профиля:', error);
            }
        }
    };

    const fetchCartItems = () => {
        const savedCart = JSON.parse(localStorage.getItem('cart'));
        if (savedCart) {
            setCartItemCount(savedCart.reduce((total, item) => total + item.quantity, 0));
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            fetchUserProfile(token);
        }

        const handleCartUpdate = (event) => {
            const updatedCart = JSON.parse(localStorage.getItem('cart'));
            if (updatedCart) {
                setCartItemCount(updatedCart.reduce((total, item) => total + item.quantity, 0));
            }
        };

        window.addEventListener('cartUpdate', handleCartUpdate);
        fetchCartItems();

        return () => {
            window.removeEventListener('cartUpdate', handleCartUpdate);
        };
    }, []);

    const getActiveIcon = (defaultIcon, activeIcon, path) => {
        return location.pathname === path ? activeIcon : defaultIcon;
    };

    return (
        <div className="navigation">
            <ul>
                <li className={`list ${location.pathname === '/' ? 'active' : ''}`}>
                    <Link to="/">
                        <span className="icon">
                            <img src={getActiveIcon(homeWhite, homePink, '/')} alt="Home" />
                        </span>
                    </Link>
                </li>

                {/* Если пользователь авторизован, показываем аватар, иначе - иконку профиля */}
                <li className={`list ${location.pathname === '/profile' ? 'active' : ''}`}>
                    {isLoggedIn && user ? (
                        <Link to="/profile">
                            <span className="icon">
                                <img 
                                    src={user.avatar || avatarIcon} 
                                    alt="Profile" 
                                    className="avatarIcon" 
                                />
                            </span>
                        </Link>
                    ) : (
                        <span className="icon" onClick={handleLogin}> {/* Модалка открывается при клике */}
                            <img src={profileWhite} alt="Profile" className="profileIcon"/>
                        </span>
                    )}
                </li>

                <li className={`list ${location.pathname === '/game' ? 'active' : ''}`}>
                    <Link to="/game">
                        <span className="icon">
                            <img src={getActiveIcon(knifeWhite, knifePink, '/game')} alt="Skins" />
                        </span>
                    </Link>
                </li>

                <li className={`list ${location.pathname === '/cart' ? 'active' : ''}`}>
                    <Link to="/cart">
                        <span className="icon" style={{ position: 'relative' }}>
                            <img src={getActiveIcon(shopWhite, shopPink, '/cart')} alt="Shop" />
                            {cartItemCount > 0 && (
                                <span className="cart-count">{cartItemCount}</span>
                            )}
                        </span>
                    </Link>
                </li>

                
                <div className="indicator"></div>
            </ul>

            <LoginModal isOpen={isLoginModalOpen} onClose={handleCloseModal} onUserLogin={handleUserLogin} />
        </div>
    );
};

export default MobNav;
