import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Navbar.css';
import Logo from '../assets/logo.png';
import searchIcon from '../assets/search.png';
import cartIcon from '../assets/shop.png';
import avatarIcon from '../assets/avatar.png';
import logoutIcon from '../assets/logout.png';
import LoginModal from './LoginModal';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const handleLogin = () => setIsLoginModalOpen(true);
  const handleCloseModal = () => setIsLoginModalOpen(false);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUser(null);
  };

  const handleUserLogin = (userData, token) => {
    setIsLoggedIn(true);
    setUser(userData);
    localStorage.setItem('token', token);
  };

  const fetchUserProfile = async (token) => {
    try {
      const response = await axios.get('https://www.kerambit.me/api/user/profile', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data);
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const updateCartItemCount = () => {
    const savedCart = JSON.parse(localStorage.getItem('cart'));
    if (savedCart) {
      const itemCount = savedCart.reduce((total, item) => total + item.quantity, 0);
      setCartItemCount(itemCount);
    }
  };

  const handleSearch = async (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    if (query.length > 0) {
      try {
        const response = await axios.get(`/api/products/search/${query}`);
        setSearchResults(response.data);
        setIsSearchVisible(true);
      } catch (error) {
        console.error('Error searching:', error);
      }
    } else {
      setSearchResults([]);
      setIsSearchVisible(false);
    }
  };

  const handleCardClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) fetchUserProfile(token);

    updateCartItemCount();

    window.addEventListener('cartUpdate', updateCartItemCount);
    return () => window.removeEventListener('cartUpdate', updateCartItemCount);
  }, []);

  return (
    <header className="navbar">
      <div className="navbar-left">
        <Link to="/" className="logo-container">
          <img src={Logo} alt="Logo" className="logo" />
          <span className="logo-text">Kerambit CS2 SKINS</span>
        </Link>
      </div>
      <nav className="navbar-center">
        <Link to="/" className={`menu-item ${location.pathname === '/' ? 'active' : ''}`}>Home</Link>
        <Link to="/game" className={`menu-item ${location.pathname === '/shop' ? 'active' : ''}`}>Skins</Link>

      </nav>
      <div className="navbar-right">
        <div className="search-box">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
          />
          <img src={searchIcon} alt="Search" className="search-icon" />
          {isSearchVisible && (
            <div className="search-results">
              {searchResults.map((product) => (
                <div key={product._id} className="search-item" onClick={() => handleCardClick(product._id)}>
                  <img src={require(`../assets/${product.imageUrl}`)} alt={product.name} className="product-icon"/>
                  <div className="product-details">
                    <div className="product-info-search">
                      <span className="product-name-search">{product.name}</span>
                      <span className="divider">|</span>
                      <span className="product-description">{product.description}</span>
                      <span className="product-wear">({product.wear})</span>
                    </div>
                    <p className="product-price-search">€{product.price.toFixed(2)}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <Link to="/cart" className="cart-icon">
          <img src={cartIcon} alt="Cart" />
          {cartItemCount > 0 && <span className="cart-count">{cartItemCount}</span>}
        </Link>
        {isLoggedIn && user ? (
              <div className="profile-section-container">
                <div className="profile-section" onClick={() => window.location.href = '/profile'}>
                  <img src={avatarIcon} alt="Avatar" className="avatar-icon" />
                  <div className="user-info">
                    <p>{user.username}</p>
                  </div>
                  <button className="logout-button" onClick={(e) => {
                    e.stopPropagation();
                    handleLogout();
                  }}>
                    <img src={logoutIcon} alt="Logout" className="logout-icon"/>
                  </button>
                </div>
              </div>
            ) : (
              <div className="login-section">
                <button className="login-button" onClick={handleLogin}>
                  Log in
                </button>
              </div>
            )}
      </div>
      <LoginModal isOpen={isLoginModalOpen} onClose={handleCloseModal} onUserLogin={handleUserLogin} />
    </header>
  );
};

export default Navbar;
