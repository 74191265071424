import React, { useState } from 'react';
import axios from 'axios';
import './LoginModal.css'; // Стили для модальных окон

const LoginModal = ({ isOpen, onClose, onUserLogin }) => {
  const [isRegister, setIsRegister] = useState(false);
  const [loginFormData, setLoginFormData] = useState({ email: '', password: '' });
  const [registerFormData, setRegisterFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [errorMessage, setErrorMessage] = useState('');

  const switchToRegister = () => {
    setIsRegister(true);
  };

  const switchToLogin = () => {
    setIsRegister(false);
  };

  const handleLoginChange = (e) => {
    setLoginFormData({
      ...loginFormData,
      [e.target.name]: e.target.value
    });
  };

  const handleRegisterChange = (e) => {
    setRegisterFormData({
      ...registerFormData,
      [e.target.name]: e.target.value
    });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://www.kerambit.me/api/auth/login', loginFormData);
      // Сохранить JWT в localStorage и передать данные в Navbar
      onUserLogin(response.data, response.data.token);
      // Закрыть модалку
      onClose();
    } catch (error) {
      setErrorMessage('Invalid login credentials');
      console.error('Login error:', error.response ? error.response.data : error.message);
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    if (!registerFormData.acceptPrivacyPolicy || !registerFormData.acceptTermsOfUse) {
      setErrorMessage("You must accept the Privacy Policy and Terms of Use.");
      return;
    }
    if (registerFormData.password !== registerFormData.confirmPassword) {
      setErrorMessage("Passwords don't match");
      return;
    }
    try {
      const response = await axios.post('https://www.kerambit.me/api/auth/register', {
        username: registerFormData.username,
        email: registerFormData.email,
        password: registerFormData.password
      });
      // Сохранить JWT в localStorage и передать данные в Navbar
      onUserLogin(response.data, response.data.token);
      // Закрыть модалку
      onClose();
    } catch (error) {
      setErrorMessage('Error during registration');
      console.error('Registration error:', error.response ? error.response.data : error.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-background">
      <div className="maincontainer">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>

        {/* Отображаем Login или Register в зависимости от состояния */}
        {!isRegister ? (
          <form onSubmit={handleLoginSubmit}>
            <h2>Login</h2>
            <div className="input-field">
              <input
                type="text"
                name="email"
                value={loginFormData.email}
                onChange={handleLoginChange}
                required
              />
              <label htmlFor="email">Enter your E-mail</label>
            </div>
            <div className="input-field">
              <input
                type="password"
                name="password"
                value={loginFormData.password}
                onChange={handleLoginChange}
                required
              />
              <label htmlFor="password">Enter your Password</label>
            </div>

            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <button type="submit">Log In</button>

            <div className="account-options">
              <p>
                Don't have an account?{' '}
                <a href="#" onClick={switchToRegister}>
                  Register
                </a>
              </p>
            </div>
          </form>
        ) : (
          <form onSubmit={handleRegisterSubmit}>
            <h2>Register</h2>
            <div className="input-field">
              <input
                type="text"
                name="username"
                value={registerFormData.username}
                onChange={handleRegisterChange}
                required
              />
              <label htmlFor="username">Enter your Username</label>
            </div>
            <div className="input-field">
              <input
                type="email"
                name="email"
                value={registerFormData.email}
                onChange={handleRegisterChange}
                required
              />
              <label htmlFor="email">Enter your E-mail</label>
            </div>
            <div className="input-field">
              <input
                type="password"
                name="password"
                value={registerFormData.password}
                onChange={handleRegisterChange}
                required
              />
              <label htmlFor="password">Enter your Password</label>
            </div>
            <div className="input-field">
              <input
                type="password"
                name="confirmPassword"
                value={registerFormData.confirmPassword}
                onChange={handleRegisterChange}
                required
              />
              <label htmlFor="confirmPassword">Repeat your Password</label>
            </div>
            <div className="checkbox-field">
              <input
                type="checkbox"
                name="acceptPrivacyPolicy"
                checked={registerFormData.acceptPrivacyPolicy}
                onChange={handleRegisterChange}
                required
              />
              <label>
                I accept the{' '}
                <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                   Privacy Policy
                </a>
              </label>
            </div>

            <div className="checkbox-field">
              <input
                type="checkbox"
                name="acceptTermsOfUse"
                checked={registerFormData.acceptTermsOfUse}
                onChange={handleRegisterChange}
                required
              />
              <label>
                I accept the{' '}
                <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                   Terms of Use
                </a>
              </label>
            </div>
            
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <button className="button-sign-up" type="submit">Sign Up</button>

            <div className="account-options">
              <p>
                Already have an account?{' '}
                <a href="#" onClick={switchToLogin}>
                  Login
                </a>
              </p>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default LoginModal;
