import React from 'react';
import './FiltersMobile.css'; // Стили для мобильных фильтров
import arrowIcon from '../assets/srelka.svg'; // Подключаем иконку стрелки

const FiltersMobile = ({
  onClose,
  filters,
  openFilterSections,
  handleFilterChange,
  handleToggleFilterSection,
  handlePriceChange,
  handleClearFilters
}) => {
  return (
    <div className="filters-mobile-container">
      <button className="filters-close-button" onClick={onClose}>
        Close
      </button>
      <h1>Filters</h1>
      
      {/* Price Filter */}
      <div className="filter-section">
        <div className="filter-header" onClick={() => handleToggleFilterSection('price')}>
          <span>Price</span>
          <img src={arrowIcon} alt="Toggle" className={`filter-arrow ${openFilterSections.price ? 'open' : ''}`} />
        </div>
        {openFilterSections.price && (
          <div className="filter-content">
            <div className="price-filter">
              <input
                type="number"
                value={filters.priceMin}
                onChange={(e) => handlePriceChange('priceMin', Number(e.target.value))}
                placeholder="€ 0"
                step="any"
                inputMode="numeric"
                className="price-input"
              />
              <span>—</span>
              <input
                type="number"
                value={filters.priceMax}
                onChange={(e) => handlePriceChange('priceMax', Number(e.target.value))}
                placeholder="€ 19999"
                step="any"
                inputMode="numeric"
                className="price-input"
              />
            </div>
          </div>
        )}
      </div>

      {/* Wear Levels Filter */}
      <div className="filter-section">
        <div className="filter-header" onClick={() => handleToggleFilterSection('wearLevels')}>
          <span>Skin Wear levels</span>
          <img src={arrowIcon} alt="Toggle" className={`filter-arrow ${openFilterSections.wearLevels ? 'open' : ''}`} />
        </div>
        {openFilterSections.wearLevels && (
          <div className="filter-checkboxes">
            <label>
              <input
                type="checkbox"
                checked={filters.wearLevels.includes('Factory new')}
                onChange={() => handleFilterChange('wearLevels', 'Factory new')}
              />
              Factory new
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.wearLevels.includes('Field-tested')}
                onChange={() => handleFilterChange('wearLevels', 'Field-tested')}
              />
              Field-tested
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.wearLevels.includes('Well-worn')}
                onChange={() => handleFilterChange('wearLevels', 'Well-worn')}
              />
              Well-worn
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.wearLevels.includes('Minimal wear')}
                onChange={() => handleFilterChange('wearLevels', 'Minimal wear')}
              />
              Minimal wear
            </label>
          </div>
        )}
      </div>

      {/* Type Filter */}
      <div className="filter-section">
        <div className="filter-header" onClick={() => handleToggleFilterSection('types')}>
          <span>Type</span>
          <img src={arrowIcon} alt="Toggle" className={`filter-arrow ${openFilterSections.types ? 'open' : ''}`} />
        </div>
        {openFilterSections.types && (
          <div className="filter-checkboxes">
            <label>
              <input
                type="checkbox"
                checked={filters.types.includes('Knives')}
                onChange={() => handleFilterChange('types', 'Knives')}
              />
              Knives
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.types.includes('Rifles')}
                onChange={() => handleFilterChange('types', 'Rifles')}
              />
              Rifles
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.types.includes('Gloves')}
                onChange={() => handleFilterChange('types', 'Gloves')}
              />
              Gloves
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.types.includes('Pistols')}
                onChange={() => handleFilterChange('types', 'Pistols')}
              />
              Pistols
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.types.includes('SMGs')}
                onChange={() => handleFilterChange('types', 'SMGs')}
              />
              SMGs
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.types.includes('Shotguns')}
                onChange={() => handleFilterChange('types', 'Shotguns')}
              />
              Shotguns
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.types.includes('Machine guns')}
                onChange={() => handleFilterChange('types', 'Machine guns')}
              />
              Machine guns
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.types.includes('Key')}
                onChange={() => handleFilterChange('types', 'Key')}
              />
              Key
            </label>
          </div>
        )}
      </div>

      {/* Rarity Filter */}
      <div className="filter-section">
        <div className="filter-header" onClick={() => handleToggleFilterSection('rarity')}>
          <span>Rarity</span>
          <img src={arrowIcon} alt="Toggle" className={`filter-arrow ${openFilterSections.rarity ? 'open' : ''}`} />
        </div>
        {openFilterSections.rarity && (
          <div className="filter-checkboxes">
            <label>
              <input
                type="checkbox"
                checked={filters.rarity.includes('Consumer Grade')}
                onChange={() => handleFilterChange('rarity', 'Consumer Grade')}
              />
              Consumer Grade
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.rarity.includes('Industrial Grade')}
                onChange={() => handleFilterChange('rarity', 'Industrial Grade')}
              />
              Industrial Grade
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.rarity.includes('Mil-Spec Grade')}
                onChange={() => handleFilterChange('rarity', 'Mil-Spec Grade')}
              />
              Mil-Spec Grade
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.rarity.includes('Restricted')}
                onChange={() => handleFilterChange('rarity', 'Restricted')}
              />
              Restricted
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.rarity.includes('Classified')}
                onChange={() => handleFilterChange('rarity', 'Classified')}
              />
              Classified
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.rarity.includes('Covert')}
                onChange={() => handleFilterChange('rarity', 'Covert')}
              />
              Covert
            </label>
          </div>
        )}
      </div>

      {/* Clear Filters */}
      <div className="filter-clear">
        <span onClick={handleClearFilters}>Clear filters</span>
      </div>
    </div>
  );
};

export default FiltersMobile;
