import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './GiftsPage.css'; // Стили для страницы
import Navbar from '../components/Navbar';
import LoginModal from '../components/LoginModal';
import MobNavbar from '../components/MobNav';

const GiftsPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [giftReceived, setGiftReceived] = useState(false); // Получен ли подарок на этой неделе
  const [timeRemaining, setTimeRemaining] = useState(null); // Время до следующего подарка
  const [giftsHistory, setGiftsHistory] = useState([]); // История полученных подарков
  const [randomGift, setRandomGift] = useState(null); // Полученный случайный подарок

  const handleToggleSidebar = (isOpen) => {
    setIsSidebarOpen(isOpen);
  }; 

  const checkAuth = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token found');
      return;
    }
  
    try {
      const response = await axios.get('https://www.kerambit.me/api/user/profile', {
        headers: { Authorization: `Bearer ${token}` }, // Убедитесь, что токен передается правильно
      });
      setUser(response.data);
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Error fetching user profile', error);
    }
  };
  

  // Проверка времени последнего подарка и запуск таймера
  const checkGiftStatus = async () => {
    if (isLoggedIn && user) {
      try {
        const response = await axios.get(`https://www.kerambit.me/api/gift/status/${user._id}`);
        const { lastGiftTime, nextGiftAvailableIn } = response.data;
        setTimeRemaining(nextGiftAvailableIn);

        if (nextGiftAvailableIn <= 0) {
          setGiftReceived(false);
        } else {
          setGiftReceived(true);
        }
      } catch (error) {
        console.error('Error checking gift status', error);
      }
    }
  };

  // Функция для получения случайного скина
  const getRandomGift = async () => {
    try {
      const response = await axios.post(`https://www.kerambit.me/api/gift/get/${user._id}`);
      setRandomGift(response.data.gift);
      setGiftReceived(true); // После получения подарок помечаем как полученный
      checkGiftStatus(); // Обновляем таймер
    } catch (error) {
      console.error('Error getting random gift', error);
    }
  };

  useEffect(() => {
    checkAuth();
    checkGiftStatus();
  }, [isLoggedIn, user]);

  useEffect(() => {
    // Таймер для обновления времени до следующего подарка
    if (timeRemaining > 0) {
      const interval = setInterval(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timeRemaining]);

  return (
    <div className={` gifts-page" ${isSidebarOpen ? 'shift-right' : ''}`}>
       <Navbar onToggleSidebar={handleToggleSidebar} />
      <MobNavbar />
      <div className="gifts-content">
        <h1>Free Skins Giveaway</h1>

        {isLoggedIn ? (
          <div>
            {giftReceived ? (
              <p>Next gift available in: {new Date(timeRemaining * 1000).toISOString().substr(11, 8)}</p>
            ) : (
              <button onClick={getRandomGift}>Get Your Free Gift!</button>
            )}

            {randomGift && (
              <div className="gift-box">
                <h2>Congratulations! You got:</h2>
                <p>{randomGift.name}</p>
                <img src={randomGift.imageUrl} alt={randomGift.name} />
              </div>
            )}

            <h2>Gifts History</h2>
            <ul>
              {giftsHistory.map((gift, index) => (
                <li key={index}>
                  {gift.name} - {gift.dateReceived}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <LoginModal />
        )}
      </div>
    </div>
  );
};

export default GiftsPage;
