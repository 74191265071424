import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './AddProductForm.css';
import LoginModal from '../components/LoginModal';

const AddProductForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    description: '',
    stock: '',
    category: '',
    isNewGame: false,
    Rarity: '',
    wear: '',
    steamApiUrl: ''
  });
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState('');
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminAccess = async () => {
      try {
        const token = localStorage.getItem('token');
        await axios.get('https://www.kerambit.me/api/admin/check-access', {
          headers: { Authorization: `Bearer ${token}` }
        });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          navigate('*');
        }
      }
    };

    checkAdminAccess();
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isLoggedIn) {
      setLoginModalOpen(true);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('price', formData.price);
      formDataToSend.append('description', formData.description);
      formDataToSend.append('stock', formData.stock);
      formDataToSend.append('category', formData.category);
      formDataToSend.append('isNewGame', formData.isNewGame);
      formDataToSend.append('Rarity', formData.Rarity);
      formDataToSend.append('wear', formData.wear);
      formDataToSend.append('image', image);
      formDataToSend.append('steamApiUrl', formData.steamApiUrl);

      const response = await axios.post('https://www.kerambit.me/api/admin/add-item', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      });

      setMessage('Product added successfully!');
    } catch (error) {
      console.error('Error adding product:', error);
      setMessage('Error adding product');
    }
  };

  const generateSteamApiUrl = () => {
    if (formData.name && formData.Rarity && formData.wear) {
      const steamUrl = `https://steamcommunity.com/market/priceoverview/?currency=1&appid=730&market_hash_name=${encodeURIComponent(formData.name + ' | ' + formData.description + ' (' + formData.wear + ')')}`;
      setFormData((prevData) => ({
        ...prevData,
        steamApiUrl: steamUrl,
      }));
    } else {
      setMessage('Please fill in the name, rarity, and wear fields to generate the Steam API URL.');
    }
  };

  const handleUserLogin = (userData, token) => {
    setIsLoggedIn(true);
    localStorage.setItem('token', token);
    setLoginModalOpen(false);
  };

  return (
    <div className="add-product-form">
      <h2>Add New Product</h2>
      {message && <p>{message}</p>}

      <LoginModal isOpen={isLoginModalOpen} onClose={() => setLoginModalOpen(false)} onUserLogin={handleUserLogin} />

      <form onSubmit={handleSubmit}>
        <input type="text" name="name" value={formData.name} onChange={handleInputChange} placeholder="Name" required />
        <input type="number" step="0.01" name="price" value={formData.price} onChange={handleInputChange} placeholder="Price" required />
        <textarea name="description" value={formData.description} onChange={handleInputChange} placeholder="Description" required></textarea>
        <input type="number" name="stock" value={formData.stock} onChange={handleInputChange} placeholder="Stock" required />
        <select name="category" value={formData.category} onChange={handleInputChange}>
          <option value="">Select category</option>
          <option value="Knives">Knives</option>
          <option value="Rifles">Rifles</option>
          <option value="Gloves">Gloves</option>
          <option value="Pistols">Pistols</option>
          <option value="SMGs">SMGs</option>
          <option value="Shotguns">Shotguns</option>
          <option value="Machine guns">Machine guns</option>
          <option value="Key">Key</option>
        </select>
        <select name="Rarity" value={formData.Rarity} onChange={handleInputChange}>
          <option value="">Select rarity</option>
          <option value="Covert">Covert</option>
          <option value="Classified">Classified</option>
          <option value="Restricted">Restricted</option>
          <option value="Mil-Spec Grade">Mil-Spec Grade</option>
          <option value="Industrial Grade">Industrial Grade</option>
          <option value="Consumer Grade">Consumer Grade</option>
        </select>
        <select name="wear" value={formData.wear} onChange={handleInputChange} required>
          <option value="">Select wear</option>
          <option value="Factory new">Factory new</option>
          <option value="Minimal wear">Minimal wear</option>
          <option value="Field-tested">Field-tested</option>
          <option value="Well-worn">Well-worn</option>
        </select>

        <label>
          <input type="checkbox" name="isNewGame" checked={formData.isNewGame} onChange={handleInputChange} />
          Is popular item?
        </label>

        <input type="file" name="image" onChange={handleImageChange} accept="image/*" required />

        <input type="url" name="steamApiUrl" value={formData.steamApiUrl} onChange={handleInputChange} placeholder="Steam API URL" required />
        
        <button type="button" onClick={generateSteamApiUrl}>Generate Steam API URL</button>
        <button type="submit">Add Product</button>
      </form>
    </div>
  );
};

export default AddProductForm;
