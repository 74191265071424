import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="term-text">
      <h1>Terms & Conditions</h1>
      <p>
        This website is owned and operated by OldSkins (Legal entity: OldSkins Ltd, No. 127896). Registration address: 579 City Road, London, United Kingdom, EC4A 5NX. By using this website you agree to the terms stated on this page. The terms may change at any time and it is your responsibility to review them regularly.
      </p>
      <h2>Governing Law</h2>
      <p>
        These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the UK law. By visiting our site and/or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content. We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes.
        Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes. These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
      </p>
      
      <h2>General Provisions</h2>
      <p>This website is administrated by OldSkins Ltd.</p>

      <h2>Definitions</h2>
      <p>
        Terms and conditions (“Terms of Service”, “Terms”) – These Regulations are the contract of a public offer, a substitute for the written or other form of agreement and have the same legal force of a written contract.
        Agreement – any agreement resulting from an Offer or Confirmation, and any agreement incorporating these Terms and Conditions by reference.
        Website – a collection of files accessed through a web address, covering a particular theme or subject, and managed by a particular person or organization.
        Services – means any product or service provided by OldSkins Ltd to Customer pursuant to and as more particularly described in the Agreement.
        Personal Data – means any Customer Data relating to an identified or identifiable natural person to the extent that such information is protected as personal data under applicable Data Protection Law.
      </p>

      <h2>Limitation of Liability</h2>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL OLDSKINS OR ITS AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR LICENSORS (A) BE LIABLE TO THE USER WITH RESPECT TO USE OF THE SITES, THE CONTENT OR THE MATERIALS CONTAINED IN OR ACCESSED THROUGH THE SITES (INCLUDING WITHOUT LIMITATION ANY DAMAGES CAUSED BY OR RESULTING FROM RELIANCE BY A USER ON ANY INFORMATION OBTAINED FROM OLDSKINS), OR ANY DAMAGES THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURES, THEFT, DESTRUCTION, FRAUD, OR UNAUTHORIZED ACCESS TO OLDSKINS RECORDS, PROGRAMS OR SERVICES; AND (B) BE LIABLE TO THE USER FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF GOODWILL, LOST PROFITS, LOSS, THEFT OR CORRUPTION OF USER INFORMATION, OR THE INABILITY TO USE THE SITES OR ANY OF THEIR FEATURES. THE USER’S SOLE REMEDY IS TO CEASE USE OF THE SITES; (C) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF OUR SITE; (D) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; AND/OR (E) USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY.
        IN NO EVENT SHALL OLDSKINS, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO OLDSKINS HEREUNDER OR WHICHEVER IS GREATER. THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF OLDSKINS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
      </p>

      <h2>Confidentiality definition</h2>
      <p>
        “Confidential Information” means any information disclosed by either party to the other party under this Agreement, either directly or indirectly, in writing, orally or by inspection of tangible objects (including, without limitation, documents, Personal Information, software, facilities, equipment and operating plans). Confidential Information may also include information disclosed to a disclosing party by third parties. Confidential Information shall not, however, include any information which (i) was publicly known and made generally available in the public domain prior to the time of disclosure by the disclosing party; (ii) becomes publicly known and made generally available after disclosure by the disclosing party to the receiving party through no action or inaction of the receiving party; (iii) is already rightfully in the possession of the receiving party at the time of disclosure by the disclosing party as shown by the receiving party’s files and records immediately prior to the time of disclosure; (iv) is obtained by the receiving party from a third party without a breach of such third party’s obligations of confidentiality; (v) is independently developed by the receiving party without use of or reference to the disclosing party’s Confidential Information, as shown by documents and other competent evidence in the receiving party’s possession; or (vi) is required by law to be disclosed by the receiving party, provided that the receiving party gives the disclosing party prompt written notice of such requirement prior to such disclosure and assistance in obtaining an order protecting the information from public disclosure and discloses only so much of the Confidential Information as is required.
      </p>
      <h2>Non-use and Non-disclosure</h2>
      <p>
         Each party agrees not to use any Confidential Information of the other party except as expressly permitted in this Agreement or as expressly authorized. Each party agrees not to disclose any Confidential Information of the other party, to third parties except as provided in Section below.
      </p>
      <h2>Terms of Agreement</h2>
      <p>
        Each party agrees that the specific commercial terms and conditions of this Agreement including but not limited too Price, Dates, Deliverables will be treated as the other parties’ Confidential Information and that no reference to the terms and conditions of this Agreement may be made in any form of press release or public statement without first consulting with the other party; provided, however, that each party may disclose the terms and conditions of this Agreement: (i) as may be required by law, if such party notifies the other party of such requirement prior to such disclosure in sufficient time to enable the other party to seek a protective order covering such disclosure; (ii) to legal counsel of the parties, provided such counsel is informed of his/her obligation to keep such disclosure in confidence with the same degree of care as the party’s other Confidential Information; (iii) in connection with the requirements of an initial public offering or securities filing provided such party seeks confidential treatment of the terms and conditions of this Agreement as appropriate; (iv) to accountants, banks and financing sources and their advisors, provided such persons and/or entities are informed of the obligation to keep such disclosure in confidence with the same degree of care as the party’s other Confidential Information; and (v) in connection with a merger or acquisition or proposed merger or acquisition or the like provided such party seeks confidential treatment of the terms and conditions of this Agreement as appropriate and provided that the person to whom the Confidential Information is disclosed are informed of the obligation to keep such disclosure in confidence with the same degree of care as the party’s other Confidential Information.
      </p>
      <h2>1. Personal Data Processing</h2>
      <p>
        1.1. KERAMBIT.ME hereby notifies its clients – natural persons, that it performs processing of personal data in accordance with the GDPR and other personal data protection laws and regulations of the UK.
        1.2. By accepting this Agreement, customer accepts personal data processing.
      </p>
      <h2>2. What is GDPR?</h2>
      <p>
        2.1. GDPR or General Data Protection Regulation is the name of the Regulation (EU) 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation). The aim of the GDPR is to protect privacy of individuals in the European Union and to harmonise the national legislation of the European countries.
        2.2. As of 25 May 2018, the GDPR is directly applicable and fully binding for data processing and protection throughout the European Union and in Estonia.
        2.3. GDPR was developed with the aim to harmonise the differences in requirements to the processing of personal data between Member States, allowing EU citizens to have better control of their own personal data, including easier access, awareness in case of identity theft, establishment of clearer rights to erasure of personal data (the “right to be forgotten”), the right to object etc.
      </p>
      <h2>3. Your Rights</h2>
      <p>
        3.1. You have the right to access your own personal data that is processed about you. You also have a right to require that your personal information be corrected if it proves to be incorrect or incomplete. Furthermore, you also have a right to be forgotten, which means that you may require that your personal information be deleted. Unless there is a special legal basis for continued processing of personal data, these will be deleted. It is voluntary to disclose personal data, and consent to the processing of personal data can always be withdrawn
      </p>
      <h2>Steam Data</h2>
      <p>
        Through this website you can access Steam data including but not limited to pictures, titles, and descriptions of virtual items in game such as Counter-Strike Global Offensive. All steam data are the property and ownership of Valve and provided on an “as is” basis. The website does not guarantee that the Steam data is accurate, up to date, or error free. However, the website is not explicitly affiliated with or endorsed by Valve.
      </p>
      <h2>Service Description</h2>
      <p>
        Through this website you can purchase license rights to virtual items on Steam. All virtual items remain the property and ownership of Valve and are provided on an “as is” basis. The website reserves the right to refuse service and refund associated payments at its sole discretion. Once rendered the service comes with no guarantees beyond initial delivery.
      </p>
      <h2>User Account</h2>
      <p>
        We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
        You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates so that we can complete your transactions and contact you as needed.
        KERAMBIT.ME is entitled to verify the data of the User by requesting from the User presentation of proper documents, their copies, scans, etc. In particular, KERAMBIT.ME may request a scan/picture of an identification document, payment method related documentation, authorization to represent the User, as well as to enter into obligations on his/her behalf for a person registering on the Site and contact data such as phone number or email address. The documents (their scans of copies) confirming the above data shall be provided within 1 working since the day, on which “website” requested providing them from a person intending to use on the Site. The lack of providing these documents (their scans or copies) may constitute the reason to refuse registration on the Site or delivering the orders (and refund)
      </p>
      <h2>Restricted Users/Use</h2>
      <p>
        You can only use this website if you are 18 years or older or with the involvement of a parent or guardian. If you are from any of the following countries you can not use our website: Iran, Syria, Israel, North Korea, Cuba, Sudan.
        In addition to other prohibitions as set forth in the Terms of Service, website users are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
      </p>
      <h2>Ordering & Delivery</h2>
      <p>
         You can only use this website if you are 18 years or older or with the involvement of a parent or guardian. If you are from any of the following countries you can not use our website: Iran, Syria, Israel, North Korea, Cuba, Sudan.
          In addition to other prohibitions as set forth in the Terms of Service, website users are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
      </p>
      <h2>Pricing</h2>
      <p>
         The delivery time of the items from the moment of confirmation of successful payment may take up to 7 business days and depends on the availability of the items you have chosen in our virtual warehouse. Orders are delivered as virtual goods to your Steam account.
      </p>
      <h2>Refunds, Returns & Replacements</h2>
      <p>
        Refunds are never issued for delivered orders and there is a sitewide zero return policy. Refunds are only issued in case payment fails verification process or if order is not delivered within the promised time and customer is unwilling to wait any further. In case we are unable to deliver we reserve the right to either refund order or replace with equivalent value in common items depending on your preference.
      </p>
      <h2>Errors, Inaccuracies & Omissions</h2>
      <p>
        Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order)
      </p>
      <h2>Indemnification</h2>
      <p>
        You agree to indemnify the website and hold it’s owners and employees harmless from any claim or demand made by a third party due to your breach of these terms or any terms included by reference, or your violation of any law or rights of a third party. Should legal action be taken by any party to enforce it’s terms under these terms, it is agreed that if the website is the prevailing party as determined by final verdict of a court of competent jurisdiction, the website shall be entitled to full recovery of all costs and fees associated with the legal action in addition to any other compensation granted by court.
      </p>
      <h2>Force Majeure</h2>
      <p>
        Neither Party shall be liable for delays and/or defaults in its performance under this Agreement due to causes beyond its reasonable control, including, but without limiting the generality of the foregoing: acts of god or of the public enemy; fire or explosion; flood; actions of the elements; epidemics, telecommunication system failure; war; acts of terrorism; riots; embargoes; quarantine; viruses; strikes; lockouts; disputes with workmen or their labor disturbances; total or partial failure of transportation, delivery facilities, or supplies; acts or requests of any governmental authority; or any other cause beyond its reasonable control, whether or not similar to the foregoing.
      </p>
      <h2>Disclaimer</h2>
      <p>
        The contract between OldSkins Ltd. and the User or Purchasing User based on these Terms of Sale as applicable to each Order via the Site and shall be governed by and interpreted in accordance with the laws of “country” and the User or Purchasing User submits to the non-exclusive jurisdiction of the “country” courts, without this, however, limiting OldSkins Ltd. ability to enforce the Contract in any court of competent jurisdiction.
        Through this website you can access Steam data including but not limited to pictures, titles, and descriptions of virtual items in game such as Counter-Strike Global Offensive. However, the website is not affiliated with or endorsed by Valve and does not guarantee that the Steam data is accurate, up to date, error free, or that it will remain unchanged in the future. All steam data are the property and ownership of Valve and provided on an “as is” basis. The services accessible through this website comes without any warranties, expressed or implied. The cardholder himself is responsible for compliance with the rules of the copyright holders.
        Any trademarks used belong to their respective owners.
      </p>
      <h2>Miscellaneous</h2>
      <p>
      OldSkins Ltd. may change these terms and conditions of use at any time, choose to display or remove posts and content on the website and/or close down the website. If the terms and conditions of use change, you will be informed of this by a new version being posted on the website. We therefore ask you to keep updated as regards any new versions of the terms and conditions. The relations between the Buyer and OldSkins Ltd. under this Agreement, which are not regulated by the terms of this Agreement, are primarily governed by the rules established by the Digital Game Production Limited partners, which also includes Settlement of Disputes, Governing Law, Entire Agreement and Changes, Severability and etc.
      </p>
    </div>
  );
};

export default TermsAndConditions;
