import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (

    <div className="privacy-text">
      <h1>Privacy Policy</h1>
      <p>
        OldSkins Ltd registered under the Law on Commercial Companies of the UK, registration number 127896, registered office: 579 City Road, London, United Kingdom, EC4A 5NX (hereinafter referred to as the “Company”), provides services on the website csskinpro.shop. (hereinafter referred to as “Website”).
      </p>

      <h2>User Accounts</h2>
      <p>
        The User can register an account on OldSkins by creating an account with their private email address. The Users take note and accept that OldSkins Ltd is in possession of tools to detect the localization of computer network devices (and the connection) as regards the country of origin from which the registration is performed. In case of discrepancies between the place of residence, place of habitual residence indicated by the User in the course of the registration process and the results of verification made by OldSkins Ltd, in particular on localization of computer network devices (and the connection) as regards the country of origin from which the registration is performed, OldSkins Ltd shall be entitled to refuse to set up the “website” account and refuse orders from the User. The User is entitled to set up only one account. For avoidance of doubt, the User will not use a VPN connection during the use of the Site.
        OldSkins Ltd is entitled to verify the data of the User by requesting from the User presentation of proper documents, their copies, scans, etc. In particular, OldSkins Ltd may request a scan/picture of an identification document, payment method related documentation, authorization to represent the User, as well as to enter into obligations on his/her behalf for a person registering on the Site and contact data such as phone number or email address. The documents (their scans of copies) confirming the above data shall be provided within 1 working day since the day on which OldSkins Ltd requested providing them from a person intending to use the Site. The lack of providing these documents (their scans or copies) may constitute the reason to refuse registration on the Site or delivering the orders (and refund).
      </p>

      <h2>Advertisement</h2>
      <p>
        If you register a user on this website you will be sent newsletters and advertisement on a weekly basis. The content of the emails will only pertain to services and content available on this website. You can opt out of receiving these emails by clicking the unsubscribe link in the footer of any such email. When you visit this website, advertisement-related cookies will be stored in your browser. These cookies are for Google Display network, Facebook, Twitter, and Instagram and will let us target you on these platforms with ads pertaining to services accessible on this website. To opt out of Google ads, simply adjust your advertisement settings in your Google profile. To opt out of the Facebook ads, simply click the gear icon in the upper right corner of the ad and opt out. You can hide specific Instagram ads or opt out of seeing ads based on your activity outside of Instagram in your device’s settings. To opt out of Twitter ads, go to your privacy settings in your Twitter account and uncheck the “Tailor ads based on information shared by ad partners”.
      </p>

      <h2>Recipients of the Personal Data</h2>
      <p>
        We may transfer your personal data to the following categories of recipients: agents, contractors, advisors or third parties who provide administrative, telecommunications, computer, payment, debt collection, data processing, legal or other services to OldSkins Ltd;
        financial institutions, payment service providers (such as banks, payment institutions, or electronic money institutions) as well as card organizations (such as VISA or MasterCard) – in connection with our payment services;
        persons and institutions that are allowed to request your personal data under applicable laws and regulations through a court order, subpoena or other legal process, such as supervisory bodies, regulators, or law enforcement bodies;
        a third party performing services on our behalf, such as to process payments;
        OldSkins Ltd operates internationally. If personal data is transferred from the European Union outside the European Economic Area, we make sure that an adequate level of personal data protection is assured.
      </p>

      <h2>Storage and Security</h2>
      <p>
        The Website OldSkins is protected by 256-bit SSL encryption, and all software is kept up to date at all times to prevent long-standing vulnerabilities. Our server is also scanned daily to ensure it is not open to attacks and that it is not hosting any malware. The server itself is located in a high-security facility protected from unauthorized access and use. Your information is protected with appropriate technical safeguards and administrative protocols, but no method of transmission over the Internet is 100% secure and no server is 100% secure. So although we do everything we can to keep our information secure, we cannot guarantee its absolute security.
      </p>

      <h2>Payments</h2>
      <p>
        We provide paid products and/or services within the Service. In that case, we use third-party services for payment processing. We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. The information about your purchase transaction is kept as long as necessary for your order to be finalized. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa and Mastercard. PCI-DSS requirements help ensure the secure handling of payment information. Our server will not store your bank card details. Payment card data is not requested and is not used for any purpose not related to payment for goods and services.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
